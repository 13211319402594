import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Amanda from './images/amanda';
import Avery from './images/avery';
import Chase from './images/chase';

import Fade from 'react-reveal/Fade';

const Testimonials = ({ children }) => (
	<StaticQuery
		query={graphql`
			query TestimonialItemsQuery {
				allTestimonialItemsJson {
					edges {
						node {
							quote
							name
							id
							class
						}
					}
				}
			}
		`}
		render={(data) => (
			<section id="testimonials">
				<div className="container">
					<Fade>
						<h2 className="yell">What are people saying?</h2>
					</Fade>
					<div className="testimonials__slider">
						<Fade left>
							<div className="testimonials__slider-quotes">
								<Fragment>{getTestimonials(data)}</Fragment>
							</div>
						</Fade>
						{clientClick()}
					</div>
				</div>
			</section>
		)}
	/>
);

function clientClick() {
	function showQuote1() {
		console.log('Show quote 1');

		// Active styles
		document.getElementById('client1').classList.add('active');
		document.getElementById('client2').classList.remove('active');
		document.getElementById('client3').classList.remove('active');

		// Showing/Hiding Quotes
		document.getElementById('one').classList.add('show');
		document.getElementById('one').classList.remove('hide');
		document.getElementById('two').classList.remove('show');
		document.getElementById('two').classList.add('hide');
		document.getElementById('three').classList.remove('show');
		document.getElementById('three').classList.add('hide');
	}
	function showQuote2() {
		console.log('Show quote 2');

		// Active styles
		document.getElementById('client1').classList.remove('active');
		document.getElementById('client2').classList.add('active');
		document.getElementById('client3').classList.remove('active');

		// Showing/Hiding Quotes
		document.getElementById('one').classList.remove('show');
		document.getElementById('one').classList.add('hide');
		document.getElementById('two').classList.add('show');
		document.getElementById('two').classList.remove('hide');
		document.getElementById('three').classList.remove('show');
		document.getElementById('three').classList.add('hide');
	}
	function showQuote3() {
		console.log('Show quote 3');

		// Active styles
		document.getElementById('client1').classList.remove('active');
		document.getElementById('client2').classList.remove('active');
		document.getElementById('client3').classList.add('active');

		// Showing/Hiding Quotes
		document.getElementById('one').classList.remove('show');
		document.getElementById('one').classList.add('hide');
		document.getElementById('two').classList.remove('show');
		document.getElementById('two').classList.add('hide');
		document.getElementById('three').classList.add('show');
		document.getElementById('three').classList.remove('hide');
	}
	return (
		<div className="testimonials__clients">
			<Fade right>
				<div id="client1" className="testimonials__clients-client active" onClick={showQuote1}>
					<Amanda />
					<div className="testimonials__clients-bar" />
				</div>
				<div id="client2" className="testimonials__clients-client" onClick={showQuote2}>
					<Avery />
					<div className="testimonials__clients-bar" />
				</div>
				<div id="client3" className="testimonials__clients-client" onClick={showQuote3}>
					<Chase />
					<div className="testimonials__clients-bar" />
				</div>
			</Fade>
		</div>
	);
}

function getTestimonials(data) {
	const testimonialItemsArray = [];
	data.allTestimonialItemsJson.edges.forEach((item) =>
		testimonialItemsArray.push(
			<div key={item.node.id} id={item.node.id} className={`testimonials__slider-quote ` + item.node.class}>
				<p className="talk">{item.node.quote}</p>
				<span className="whisper">{item.node.name}</span>
			</div>
		)
	);
	return testimonialItemsArray;
}

export default Testimonials;
