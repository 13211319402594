import React from 'react';

import Brain from './images/brain';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

function showTooltip1() {
	console.log('Show tooltip 1');

	// Active styles
	document.getElementById('tooltip1').classList.toggle('active');
	document.getElementById('tooltip2').classList.remove('active');
	document.getElementById('tooltip3').classList.remove('active');
	document.getElementById('icon1').classList.add('active');
}

function showTooltip2() {
	console.log('Show tooltip 1');

	// Active styles
	document.getElementById('tooltip1').classList.remove('active');
	document.getElementById('tooltip2').classList.toggle('active');
	document.getElementById('tooltip3').classList.remove('active');
	document.getElementById('icon2').classList.add('active');
}

function showTooltip3() {
	console.log('Show tooltip 1');

	// Active styles
	document.getElementById('tooltip1').classList.remove('active');
	document.getElementById('tooltip2').classList.remove('active');
	document.getElementById('tooltip3').classList.toggle('active');
	document.getElementById('icon3').classList.add('active');
}

function hideTooltips() {
	console.log('Show tooltip 1');

	// Active styles
	document.getElementById('tooltip1').classList.remove('active');
	document.getElementById('tooltip2').classList.remove('active');
	document.getElementById('tooltip3').classList.remove('active');
	document.getElementById('icon1').classList.remove('active');
	document.getElementById('icon2').classList.remove('active');
	document.getElementById('icon3').classList.remove('active');
}

function Explainer() {
	return (
		<section id="explainer">
			<div className="explainer__content">
				<Fade>
					<div className="explainer__content-info-icons">
						<i id="icon1" class="fas fa-plus-circle" onClick={showTooltip1} onMouseLeave={hideTooltips} />
						<i id="icon2" class="fas fa-plus-circle" onClick={showTooltip2} onMouseLeave={hideTooltips} />
						<i id="icon3" class="fas fa-plus-circle" onClick={showTooltip3} onMouseLeave={hideTooltips} />
					</div>
				</Fade>
				<div className="explainer__content-tooltips">
					<div id="tooltip1" className="explainer__content-tooltips-tooltip">
						<p className="talk">Strengthen your problem solving abilities and refine your concentration.</p>
					</div>
					<div id="tooltip2" className="explainer__content-tooltips-tooltip">
						<p className="talk">Seemlessly pair your your body and mind.</p>
					</div>
					<div id="tooltip3" className="explainer__content-tooltips-tooltip">
						<p className="talk">Create a vision to drive yourself forward and master your craft.</p>
					</div>
				</div>
				<div className="container">
					<div className="explainer__content-graphic">
						<Zoom>
							<Brain />
						</Zoom>
					</div>
					<div className="explainer__content-summary">
						<Fade>
							<h2 className="yell">What is a Strong Mind?</h2>
							<p className="talk">
								At the core of human performance is the ability to stay focused in the present moment. A
								Strong Mind is a trained and conditioned mind. A Strong Mind is Disciplined, Resilient,
								and Determined. A Strong Mind allows growth. Having a Strong Mind gives you the
								competitive edge in any performance.
							</p>
						</Fade>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Explainer;
