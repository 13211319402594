import React from 'react';
import { Link } from 'gatsby';

import HomeLayout from '../layout/homepage';

import Intro from '../components/intro';
import Subscribe from '../components/subscribe';
import Explainer from '../components/explainer';
import Testimonials from '../components/testimonials';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/adaptix.jpg';

const IndexPage = () => (
	<HomeLayout>
		<SEO
			title="Home | Strong Mind Performance Consulting"
			description="Strengthening the mind of athletes through sport psychology principles."
			image={ogImage}
		/>
		<Intro />
		<Subscribe />
		<Explainer />
		<Testimonials />
	</HomeLayout>
);

export default IndexPage;
