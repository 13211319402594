import React from 'react';
import { Link } from 'gatsby';

import IntroBackground from './images/introBackground';

import Fade from 'react-reveal/Fade';

function Intro() {
	return (
		<section id="intro">
			<Fade>
				<IntroBackground />
			</Fade>
			<div className="intro__content">
				<div className="container">
					<Fade>
						<h1 className="shout">Are you ready to train your mind?</h1>
						<p className="talk">
							Training the mind gives individuals the skills to reach their fullest potential
						</p>
						<Link className="btn btn__accent" to="/services/">
							View our services
						</Link>
					</Fade>
				</div>
			</div>
		</section>
	);
}

export default Intro;
