import React from 'react';

import Fade from 'react-reveal/Fade';

function Subscribe() {
	return (
		<section id="subscribe">
			<div className="container">
				<h2 className="yell">Subscribe to Strong Mind</h2>
				<p className="talk">Stay up to date and build your competitive edge.</p>
				<form name="Subscribe" method="POST" data-netlify="true" action="/thankyou">
					<Fade>
						<input type="hidden" name="form-name" value="Subscribe" />

						<input
							id="contactEmail"
							type="email"
							name="email"
							autocomplete="off"
							placeHolder="Email address"
							required
						/>
						<button className="btn btn__accent" type="submit">
							Subsrcibe
						</button>
					</Fade>
				</form>
			</div>
		</section>
	);
}

export default Subscribe;
